import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';


@Directive({
  selector: '[appInputRequiredValidator]',
  inputs: ['appInputRequiredValidator.useTranslationKey', 'appInputRequiredValidator.trigger'],
  exportAs: 'appInputRequiredValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputRequiredValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)}
  ]
})
export class InputRequiredValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector = 'appInputRequiredValidator';
  protected override translationKey = 'validation.input.appInputRequiredValidator';

  override ngOnInit() {
    super.ngOnInit();

    if (this.host) {
      this.host.hasRequiredValidator = true;
    }

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.host) {
      this.host.hasRequiredValidator = false;
    }
  }

  validator: ValidatorFn = (control) => {
    const errors: ValidationErrors = {'appInputRequiredValidator': this.translation};
    return control?.value ? null : errors;
  };

}
