import { AfterViewInit, Directive, Host, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';
import { Observable, Subscription } from 'rxjs';
import { BaseFormComponent } from '../base-form.component';
import { coerceBoolean } from '@util/functions/objects';


@Directive({
  selector: '[baseValidator]'
})
export abstract class BaseValidatorDirective implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  /**
   * needs to be overridden by every child and must be the part of the selector infront of the dot
   */
  protected abstract _thisSelector: string;

  private _formGroupName: string;
  private _instantErrorDisplay = false;
  private activeTimeOut!: ReturnType<typeof setTimeout>;
  private triggerSub: Subscription;

  protected translation = '';
  protected translationSub: Subscription;
  protected translationKey = 'validation.noTextSet';

  protected _useTranslationKey: string;

  get formGroupName(): string {
    return this._formGroupName;
  }

  @Input()
  set formGroupName(value: string) {
    this._formGroupName = value;
    this.utilInteroperationalFormGroupService.registerValidator(value, this);
  }

  @Input()
  set instantErrorDisplay(value: boolean) {
    this._instantErrorDisplay = coerceBoolean(value);
    // deactivates the timer and updates the state of the dynamically updated directives
    if(!this._instantErrorDisplay && this.activeTimeOut) {
      clearTimeout(this.activeTimeOut);
      this.host.formControl.markAsUntouched();
    }
  }

  @Input()
  set useTranslationKey(value: string) {
    this._useTranslationKey = value;
    this.updateTranslation();
  }

  @Input()
  set trigger(value: Observable<any>) {
    this.triggerSub?.unsubscribe();
    this.triggerSub = value?.subscribe(_ => {
      this.host.formControl.updateValueAndValidity();
    });
  }

  constructor(@Host() public host: BaseFormComponent, private utilInteroperationalFormGroupService: UtilOmnipresentFormGroupService, protected translationService: TranslationService, protected ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.updateTranslation();
  }

  ngAfterViewInit(): void {
    if (this.formGroupName) {
      this.utilInteroperationalFormGroupService.updateValueAndValidityOfFormGroup(this.formGroupName);
    }

    if (this._instantErrorDisplay) {
      this.activeTimeOut = setTimeout(() => {
        this.host.formControl.markAsTouched();
        this.host.formControl.updateValueAndValidity();
        if (this.formGroupName) {
          this.utilInteroperationalFormGroupService.updateValueAndValidityOfFormGroup(this.formGroupName);
        }
      }, 0);
    }
  }

  /** For @Input changes with the dot notation */
  ngOnChanges(changes: SimpleChanges) {

    Object.keys(changes).forEach(key => {
      if (key.includes('.')) {
        const parts = key.split('.');
        const wantedKey = parts.length === 2 && parts[0] === this._thisSelector ? parts[1] : void 0;
        if (wantedKey) {
          const val = changes[key].currentValue;
          this[wantedKey] = val;
        }
      }
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.activeTimeOut);
    this.translationSub?.unsubscribe();
    this.triggerSub?.unsubscribe();

    if (this.formGroupName) {
      this.utilInteroperationalFormGroupService.unregisterValidator(this.formGroupName, this);
    }
  }

  protected updateTranslation(options?: Record<any, any>) {

    this.translationSub?.unsubscribe();
    this.translationSub = this.translationService.translate((this._useTranslationKey || this.translationKey), options).subscribe(tr => {
      this.translation = tr;
    });

  }

}
